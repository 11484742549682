import queryString from "query-string";
import { ParsedUrlQueryInput } from "querystring";

import QueryParams from "models/queryParams.model";

// Parse params based on the API structure and return stringified query.
const parseQueryParams = ({
  pagination,
  sort,
  filter,
  date_range,
  userId,
}: QueryParams): string => {
  let query: ParsedUrlQueryInput = {
    filter: JSON.stringify({
      active: filter?.machineStatusActive,
      deleted: filter?.resourceDeleted,
      user_id: filter?.userId,
      user_ids: filter?.userIds,
      roles: filter?.userRoleNames,
      company_id: filter?.companyId,
      company_ids: filter?.companyIds,
      location_ids: filter?.locationIds,
      machine_ids: filter?.machineIds,
      pos_ids: filter?.vposIds,
      qr_ids: filter?.qrIds,
      order_ids: filter?.orderIds,
      object_entity_ids: filter?.objectEntityIds,
      invoice_company_ids: filter?.invoiceCompanyIds,
      type: filter?.paymentPointType,
      payment_types: filter?.transactionPaymentTypes,
      product_type: filter?.productType,
      request_invoice: filter?.requestInvoice,
      issuer_company_ids: filter?.issuerCompanyIds,
      receiver_company_ids: filter?.receiverCompanyIds,
      status: filter?.companyStatus,
      statuses: filter?.transactionStatuses,
      q: filter?.searchQuery,
      from:
        typeof filter?.from !== "undefined"
          ? {
              start_date: filter.from.startDate,
              end_date: filter.from.endDate,
            }
          : undefined,
      to:
        typeof filter?.to !== "undefined"
          ? {
              start_date: filter.to.startDate,
              end_date: filter.to.endDate,
            }
          : undefined,
      date_range:
        typeof filter?.dateRange !== "undefined"
          ? {
              start_date: filter.dateRange.startDate,
              end_date: filter.dateRange.endDate,
            }
          : undefined,
    }),
  };

  if (typeof pagination !== "undefined") {
    const { page, perPage } = pagination;
    query = {
      ...query,
      range: JSON.stringify({ page: page, page_size: perPage }),
    };
  }

  if (typeof sort !== "undefined") {
    const { field, order } = sort;
    query = {
      ...query,
      sort:
        field?.length > 0 && typeof field === "object"
          ? JSON.stringify({ [field[0]]: { [field[1]]: order } })
          : JSON.stringify({ [field as string]: order }),
    };
  }

  if (typeof date_range !== "undefined") {
    query = {
      ...query,
      date_range: JSON.stringify(date_range),
    };
  }

  if (typeof userId !== "undefined") {
    query = {
      ...query,
      user_id: JSON.stringify(userId),
    };
  }

  return `?${queryString.stringify(query)}`;
};

export default parseQueryParams;

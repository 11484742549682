import { useTranslation } from "react-i18next";

import DynamicChart from "components/Chart/DynamicChart";
import { getChartData } from "components/Chart/chartHelper";
import Order from "models/resources/order.model";
import Transaction from "models/resources/transaction.model";

interface LineChartProps {
  dataArray: Order[] | Transaction[];
  dataType: string;
  dateRange?: {
    startDate: string;
    endDate: string;
  };
}

function LineChart({ dataArray, dataType, dateRange }: LineChartProps) {
  const { t } = useTranslation();
  const { labels, data, currencyName, total, revenueByCurrency } = getChartData(
    dataArray,
    dataType,
    dateRange,
  );

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "left" as const,
      },
      title: {
        display: true,
        text: t("Daily revenue for period selected"),
        align: "end",
        padding: {
          top: 0,
          bottom: 70,
          //right: 30
        },
      },
    },
    font: { family: "Poppins-Bold" },
    maintainAspectRatio: false,
  };

  const dataLine = {
    labels,
    datasets: [
      {
        type: "line" as const,
        label: currencyName === "EUR" ? t("EUR/day") : t("BGN/day"),
        borderColor: "#78BCC4",
        borderWidth: 2,
        fill: true,
        data,
      },
    ],
  };
  return (
    <DynamicChart
      mainHeader={true}
      options={options}
      data={dataLine}
      total={total}
      currency={currencyName}
      revenueByCurrency={revenueByCurrency.substring(
        0,
        revenueByCurrency.length - 2,
      )}
    />
  );
}

export default LineChart;

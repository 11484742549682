import { SetStateAction, Dispatch, useState } from "react";

import { Box } from "@mui/material";

import DynamicForm from "components/Forms/generic/DynamicForm";
import ModuleAction, {
  ModuleActions,
} from "components/ModuleActions/ModuleAction";
import DynamicDialog from "components/layouts/Dialog";
import Module from "models/resources/module.model";

const ModuleActionsBar = ({
  isActive,
  moduleId,
  hasCompany,
  setUpdateStatus,
  setData,
  tableData,
}: {
  isActive: boolean;
  moduleId: number;
  hasCompany: boolean; // Used for disabling `Deatch from company` button.
  setUpdateStatus?: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<Module>>;
  tableData: any;
}) => {
  const [showFirmwareForm, setShowFirmwareForm] = useState<boolean>(false);
  const [showFlowForm, setShowFlowForm] = useState<boolean>(false);
  return (
    <Box
      sx={{
        margin: "10px 0px",
      }}
    >
      {showFirmwareForm && (
        <DynamicDialog
          hideActions={true}
          hideButton={true}
          exitAction={setShowFirmwareForm}
          exitActionType="boolean"
          title={"Update firmware"}
          component={
            <DynamicForm
              mode="edit"
              call="modules"
              callEndPoint="firmware"
              resource="module-firmware"
              editId={moduleId}
              tableData={tableData}
            />
          }
          isOpen={true}
        />
      )}
      {showFlowForm && (
        <DynamicDialog
          hideActions={true}
          hideButton={true}
          exitAction={setShowFlowForm}
          exitActionType="boolean"
          title={"Module Flow"}
          component={
            <DynamicForm
              mode="edit"
              call="modules"
              callEndPoint="flow"
              resource="module-flow"
              editId={moduleId}
              tableData={tableData}
            />
          }
          isOpen={true}
        />
      )}
      <ModuleAction
        action={isActive ? ModuleActions.Disable : ModuleActions.Enable}
        moduleId={moduleId}
        setUpdateStatus={setUpdateStatus}
        setData={setData}
      />
      <ModuleAction
        action={ModuleActions.UpdateFirmware}
        moduleId={moduleId}
        showFormSetter={setShowFirmwareForm}
        setData={setData}
      />
      <ModuleAction
        action={ModuleActions.ModuleFlow}
        moduleId={moduleId}
        showFormSetter={setShowFlowForm}
        setData={setData}
      />
      <ModuleAction
        action={ModuleActions.ResetTask}
        moduleId={moduleId}
        setData={setData}
      />
      {hasCompany && (
        <ModuleAction
          action={ModuleActions.Detach}
          moduleId={moduleId}
          setData={setData}
        />
      )}
    </Box>
  );
};

export default ModuleActionsBar;

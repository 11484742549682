import React, { Fragment, ReactNode } from "react";

import { Box, Grid } from "@mui/material";

import LanguageSelect from "components/LanguageSelect";
import Loader from "components/Loader";
import AuthLayout, {
  AuthLayoutProps,
} from "components/layouts/AuthLayout/AuthLayout";

const InfoLayout = ({ component }: AuthLayoutProps) => {
  return (
    <Fragment>
      <Loader />
      <Grid
        container
        sx={{
          height: "100vh",
          backgroundImage: "linear-gradient(to bottom right, #46D2D2, #6A6AFE)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="40px 40px 0px 0px"
          >
            <LanguageSelect />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
            color="primary"
          >
            <AuthLayout component={component} />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default InfoLayout;

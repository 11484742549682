import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography } from "@mui/material";

import UploadLogo from "../UploadLogo";
import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import useAuthContext from "context/AuthContext";
import { FieldDescriptor } from "models/fieldsEntities.model";
import CompanyModel from "models/resources/company.model";
import { getDataById } from "services/requests/listData";

type CircleIconVariants = "success" | "error" | "warning";

const CompanyDetails = () => {
  const { user } = useAuthContext();
  const [companyData, setCompanyData] = useState<CompanyModel>();
  const { t } = useTranslation();
  const { id } = useParams();

  const getCompanyStatus = (status: string) => {
    const CompanyStatusJSX = (status: CircleIconVariants) => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CheckCircleIcon color={status} />
          <Typography>{t(status)}</Typography>
        </div>
      );
    };
    switch (status) {
      case "approved":
        return CompanyStatusJSX("success");
      case "declined":
        return CompanyStatusJSX("error");
      case "waiting":
        return CompanyStatusJSX("warning");
      default:
        return <Typography component="p">---</Typography>;
    }
  };

  useEffect(() => {
    if (id) {
      getDataById(setCompanyData, `companies/${id}`);
    }
  }, [id]);

  const fields: FieldDescriptor[] = [
    {
      value: "company",
      label: t("This company is waiting to be approved"),
      type: "AppBar",
      show: user?.role?.type === "admin" && companyData?.status === "waiting",
    },
    {
      value: companyData?.name,
      label: "Company name",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.display_name,
      label: "Display name",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.email,
      label: "Email",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.phone,
      label: "Phone number",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.receive_sms === true ? t("Yes") : t("No"),
      label: "Do you wish to receive SMS?",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.info,
      label: "Company info",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.country,
      label: "Country",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.state,
      label: "Country state",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.city,
      label: "City",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.address,
      label: "Address",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.tax_number,
      label: "UIC",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.next_invoice_number,
      label: "Next invoice number",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.accountable_person,
      label: "Accountable person",
      type: "InfoText",
      show: true,
    },
    {
      value: companyData?.vat_load_gift_cards,
      label: "VAT% (load prepaid cards)",
      type: "InfoText",
      show: true,
    },
    {
      value: getCompanyStatus(companyData?.status ?? ""),
      label: "Status",
      type: companyData?.status ? "Icon" : "TextField",
      show: true,
    },
    {
      value: "",
      label: "",
      type: "BlankSpace",
      show: true,
      inlineStyle: {
        margin: 20,
      },
    },
    {
      type: "CustomComponent",
      value: <UploadLogo />,
      label: "",
      show: true,
    },
    {
      value: t("Change status"),
      label: `${t("Change status")} ${
        companyData?.status === "approved" ? "declined" : "approved"
      }`,
      type: "ChangeCompanyStatus",
      text: `${t("Are you sure?")} ${t(
        "You are about to change the status of the company to",
      )} ${
        companyData?.status === "approved" ? t("declined") : t("approved")
      }.`,
      show: companyData?.status !== "waiting" && user?.role?.type === "admin",
    },
    {
      value: "",
      label: t("Payment Credentials"),
      type: "PaymentCredentials",
      show: true,
    },
    {
      value: "",
      label: t("API Credentials"),
      type: "ApiCredentials",
      show: true,
    },
  ];

  return (
    <ContentLayout
      title={`${t("Companies")} > ${companyData?.display_name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={companyData}
        setData={setCompanyData}
        resource={"company"}
        call={"companies"}
        fields={fields}
      />
    </ContentLayout>
  );
};

export default CompanyDetails;

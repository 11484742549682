import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import CircleIcon from "@mui/icons-material/Circle";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";
import { FieldDescriptor } from "models/fieldsEntities.model";

import useGiftCardDetails from "./useGiftCardDetails";

const GiftCardsDetails = () => {
  const { giftCardData, setGiftCardData } = useGiftCardDetails();
  const { t } = useTranslation();
  if (!giftCardData) return <Fragment />;

  let fields: FieldDescriptor[] = [
    {
      value: giftCardData?.name,
      label: "Name",
      type: "InfoText",
      show: true,
    },
    {
      value: giftCardData.company.display_name || giftCardData.company.name,
      id: giftCardData.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: t(giftCardData?.currency),
      label: "Currency",
      type: "InfoText",
      show: true,
    },
    {
      value: t(giftCardData?.type),
      label: "Type",
      type: "InfoText",
      show: true,
    },
    {
      value: giftCardData?.discount_value,
      label: "Value",
      type: "InfoText",
      show: true,
    },
    {
      value: giftCardData.usage_conditions[0]
        ? `${giftCardData.usage_conditions[0].validity_amount} ${t(
            giftCardData.usage_conditions[0].validity_type,
          )}`
        : "",
      label: "Valid for",
      type: "InfoText",
      show: true,
    },
    {
      value: giftCardData.description,
      label: "Description",
      type: "DescriptiveInfoText",
      show: true,
    },
  ];

  if (giftCardData.type !== "promotional") {
    fields = [
      ...fields,
      {
        value: giftCardData?.price,
        label: "Purchase price",
        type: "InfoText",
        show: true,
      },
      {
        value: (
          <CircleIcon
            sx={{ color: giftCardData.active ? "#4CAF50" : "#FF6347" }}
          />
        ),
        label: "Active ",
        type: "Icon",

        show: true,
      },
    ];
  }

  return (
    <ContentLayout
      title={`${t("Gift Cards")} > ${giftCardData?.name}`}
      filters={false}
    >
      <CustomDetailsPage
        data={giftCardData}
        setData={setGiftCardData}
        resource={"gift-cards"}
        call={"gift-cards"}
        fields={fields}
        isDeletable={!giftCardData.active}
      />
    </ContentLayout>
  );
};

export default GiftCardsDetails;

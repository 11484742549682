import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

import constants from "./styles/scss/constants.module.scss";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: constants.primary,
    },
    secondary: {
      main: constants.textColor,
    },
    info: {
      main: constants.primary,
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    h6: {
      textTransform: "uppercase",
      color: constants.primary,
      fontFamily: constants.semiBold,
      fontSize: "12px",
      fontWeight: "bold",
    },
    subtitle2: {
      fontFamily: constants.bold,
      color: constants.textColor,
    },
    body2: {
      lineHeight: "1.8rem",
    },
  },

  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: constants.grey,
          transition: "0.3s",
          "&:hover": {
            opacity: "0.7",
            color: "##eae7e7",
            textShadow: constants.textShadow,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        containedSizeSmall: {
          padding: "4px 6px",
          fontSize: "10px",
          fontFamily: constants.semiBold,
          boxShadow: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#78BCC4",
          fontWeight: "bold",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          display: "block",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          top: "-9px!important",
          left: "-6px",
          "&.Mui-focused": {
            transform: "translate(10px, -9px) scale(0.75) !important",
            color: constants.textColor,
            fontWeight: "bold",
          },
        },
      },
    },
    //.css-hid01z-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-9px!important",
          // left: "-6px",
          "&.Mui-focused": {
            transform: "translate(10px, -9px) scale(0.75) !important",
            color: constants.textColor,
            fontWeight: "bold",
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          "&:not(:last-of-type)": {
            borderRight: "none",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          "@media (min-width: 600px)": {
            paddingLeft: "0",
            paddingRight: "0",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: "5px",
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h3: { fontWeight: "bold" },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
        paper: {
          backgroundColor: constants.bgNav,
          color: constants.secondary,
          paddingTop: "10px",
        },
        paperAnchorRight: {
          backgroundColor: constants.secondary,
          padding: "40px 20px",
          color: constants.textColor,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: `1px solid ${constants.borderLight}`,
          //maxWidth: 'unset!important',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: constants.secondary,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0px!important",

          "&:not(:last-of-type)": {
            marginBottom: "30px!important",
            //marginRight: "20px!important",  do not set it back /breakes all forms
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: "0px!important",
        },
        input: {
          padding: "7.5px 4px 7.5px 6px",
          // borderColor: "transparent",
          // backgroundColor: constants.bgLight,
        },
        notchedOutline: {
          borderColor: "transparent",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.MuiSelect-icon": {
            color: "#9E9E9E",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflowY: "unset",
          backgroundColor: constants.bgDialog,
          boxShadow: constants.boxShadow,
          borderRadius: "12px",
          padding: "25px",
          maxWidth: "80%",
          maxHeight: "80%",
          minWidth: "60%",
          // minHeight: "60%",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBottom: "0px!important",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          //padding: "0!important",
          marginBottom: "0px!important",
          paddingTop: "16px!important",
          "& .MuiBox-root": {
            marginBottom: "20px",
            paddingTop: "0px",
          },
          "& a": {
            marginBottom: "30px",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "15px!important",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: constants.bg,
          backdropFilter: "blur(4px)",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: "0px!important",
          "& .MuiFormControl-root": {
            marginBottom: "30px!important",
          },
          "& .MuiBox-root": {
            marginTop: "-45px",
            paddingTop: "0",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
          fontSize: "0.7em",
          textTransform: "uppercase",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
  },
});

export default theme;

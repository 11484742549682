import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, InputLabel, TextField } from "@mui/material";

import DynamicDialog from "components/layouts/Dialog";

import useUploadLogo from "./useUploadLogo";

const UploadLogo = () => {
  const { t } = useTranslation();
  const {
    show,
    handleDialogShowChange,
    image,
    imageSrc,
    handleSelectedImageOnChange,
    crop,
    handleCropOnChange,
    imgRef,
    previewCanvasRef,
    cropImage,
  } = useUploadLogo();

  return (
    <Fragment>
      {image !== null && (
        <Box
          sx={{
            display: "inline-block",
          }}
        >
          <InputLabel
            sx={{
              color: "rgba(0, 0, 0, 0.38)",
              fontSize: "12px",
              marginLeft: "7px",
              maxHeight: "13px!important",
            }}
            variant="standard"
          >
            {t("Logo")}
          </InputLabel>
          <img src={image} width={180} height={180}></img>
        </Box>
      )}

      <DynamicDialog
        isOpen={show}
        title="Crop"
        hideButton
        onConfirm={() => {
          cropImage();
          handleCropOnChange({
            unit: "%", // Can be 'px' or '%'
            x: 0,
            y: 0,
            width: 0,
            height: 0,
          });
        }}
        onCancel={() => {
          handleDialogShowChange();
          handleCropOnChange({
            unit: "%", // Can be 'px' or '%'
            x: 0,
            y: 0,
            width: 0,
            height: 0,
          });
        }}
        confirmDisabled={crop.height === 0}
        component={
          <Box alignItems="center" justifyContent="center" display="flex">
            <ReactCrop aspect={1} crop={crop} onChange={handleCropOnChange}>
              <img ref={imgRef} src={imageSrc} />
            </ReactCrop>
          </Box>
        }
      />

      <canvas
        hidden
        ref={previewCanvasRef}
        style={{
          border: "1px solid black",
          objectFit: "contain",
          width: crop.width,
          height: crop.height,
        }}
      />

      <Button
        sx={{
          boxShadow: "none",
        }}
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        component="label"
      >
        {t("Upload logo")}
        <input
          type="file"
          accept="image/png, image/jpeg, image/webp"
          hidden
          onChange={(e) => {
            // This order of function for not opening the dialog window on size validation in handleSelectedImageOnChange;
            handleDialogShowChange();
            handleSelectedImageOnChange(e);
          }}
          //@ts-ignore
          // Reset target value to be able to reopen the dialog window for image crop on selecting the same picture more than 1 time in a row.
          onClick={(e) => (e.target.value = null)}
        />
      </Button>
    </Fragment>
  );
};

export default UploadLogo;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

import GlobalCompanyFilter from "components/GlobalCompanyFilter";
import SideBarLinks from "components/SidebarLinks";
import UserPopper from "components/UserPopper";
import constants from "styles/scss/constants.module.scss";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  "&::-webkit-scrollbar-track": {
    background: "grey ",
  },
  border: 0,
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface SideBarProps {
  isOpen: boolean;
  sendIsOpen: (value: boolean) => void;
  sendDrawerWidth: (data: number) => void;
}
const SideBar = ({ isOpen, sendDrawerWidth, sendIsOpen }: SideBarProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(isOpen);
  const theme = useTheme();
  const { t } = useTranslation();

  const handleDrawer = () => {
    sendDrawerWidth(drawerWidth);
    sendIsOpen(open);
    setOpen(!open);
  };
  return (
    <Box>
      <AppBar
        elevation={0}
        position="fixed"
        open={open}
        sx={{ padding: "0px!important" }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <IconButton onClick={handleDrawer} color="secondary">
              {open ? (
                <ChevronLeftIcon />
              ) : (
                <div style={{ marginLeft: "60px" }}>
                  <ChevronRightIcon />
                </div>
              )}
            </IconButton>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <GlobalCompanyFilter />

              <UserPopper />
            </Box>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <div onClick={() => navigate("/account/dashboard")}>
          <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
            <img
              src="/averato-logo.svg"
              alt="Averato Logo"
              width={70}
              height={65}
            />
            <Typography
              style={{
                marginLeft: "10px",
                textTransform: "uppercase",
                fontFamily: constants.semiBold,
                fontWeight: "unset",
                fontSize: "16px",
              }}
              component="span"
              color="primary"
            >
              All-in-one
            </Typography>
          </Box>
        </div>
        <SideBarLinks />
      </Drawer>
    </Box>
  );
};

export default SideBar;

import { Fragment, useLayoutEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import * as _ from "lodash";

import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import Data from "../../DatePicker/DatePicker";
import ImageUpload from "../custom/ImageUpload";
import RecipeProductIngredients from "../custom/RecipeProductIngredients";
import MdbSettingsForm from "components/Forms/custom/MdbSettingsForm";
import { getAutoCompleteValue } from "components/Forms/formUtils";
import { MapWithMarkerOnClick } from "components/MapContainer/CreateEditMap";
import DynamicDialog from "components/layouts/Dialog";
import useAuthContext from "context/AuthContext";
import {
  AutoCompleteOption,
  FieldDescriptor,
  RadioButtonOption,
} from "models/fields.model";
import {
  flowTypes,
  ingredientUnits,
  productTypes,
  roles,
} from "utils/constants";

import DynamicForm from "./DynamicForm";

const promotionTypes: string[] = ["promotional"];
const allCurrencies: string[] = ["USD", "BGN", "EUR", "CHF"];
const giftCardType: string[] = ["conventional", "promotional"];
const giftCardValidPeriod: string[] = ["day", "week", "month", "year"];
const useFieldGenerator = () => {
  const fieldToWatch = useRef("");
  const { t } = useTranslation();
  const [getChangedLatLng, setLatLng] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });

  const generateField = (
    mode: string,
    field: FieldDescriptor,
    getValues: Function,
    setValue: Function,
    control: Control<FieldValues>,
    errors: FieldErrors,
    watch: UseFormWatch<FieldValues>,
    tableData?: any,
  ) => {
    const { user } = useAuthContext();
    const label = t(field.label ?? "").concat(field.required ? "*" : "");
    const [isDependableMissing, setDependableMissing] = useState(false);
    const [onChangeError, setOnChangeError] = useState<string>("");
    let dependables = watch(field.dependantOn);
    let warnings: string = "";

    if (field.emptyOptions) {
      let requiredValues = field.requiredValues?.map((value) =>
        getValues(value),
      );
      warnings = field?.emptyOptions(requiredValues ?? [], field.messages);
    }

    if (field.dependantOn && field.dependantOn?.length != 0) {
      useLayoutEffect(() => {
        let isMissing: boolean = false;
        if (
          dependables.includes(undefined) ||
          dependables.includes(null) ||
          dependables.includes("") ||
          dependables.some(
            (dependable) => Array.isArray(dependable) && dependable.length == 0,
          )
        )
          isMissing = true;
        setDependableMissing(() => isMissing);
      }, dependables);
    }

    if (field.isFieldValueConditional)
      // Other fields depend on this field. (To make API call based on the selected ID)
      // The form will listen to changes of this field and make new API calls once the value changes
      fieldToWatch.current = field.id;

    if (isDependableMissing) return;
    if (field.editOnly && mode !== "edit") return;
    if (
      field.hide ||
      (mode === "edit" && field.hideOnEdit) ||
      (getValues("type") === "promotional" &&
        (field.id === "price" || field.id === "active"))
    ) {
      return;
    }

    // React hook form dictates the usage of a Controller. All non-html elements such as MUI ones have to
    // be wrapped in a Controller

    switch (field.type) {
      case "Seperator":
        return (
          <div>
            <hr></hr>
          </div>
        );
      case "Switch":
        return (
          <Controller
            control={control}
            defaultValue={field.defaultValue ?? false}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ paddingRight: 2 }}>
                {t(field.label!)}
                <Switch checked={value} onChange={onChange}></Switch>
              </FormControl>
            )}
            name={field.id}
            key={field.id}
          />
        );
      case "FileUpload":
        return (
          <Fragment>
            {typeof getValues("product_image") !== "undefined" && (
              <p>{getValues("product_image").name}</p>
            )}
            <Button
              sx={{
                boxShadow: "none",
              }}
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              component="label"
            >
              {t("Upload image")}
              <input
                type="file"
                hidden
                onChange={(e) => field.onChange!({ setter: setValue }, e)}
                //@ts-ignore
                // Reset target value to be able to reopen the dialog window for image crop on selecting the same picture more than 1 time in a row.
                onClick={(e) => (e.target.value = null)}
              />
            </Button>
          </Fragment>
        );
      case "TextField":
        return (
          <Controller
            control={control}
            name={field.id}
            key={field.id}
            rules={{
              required: field.required ? "This field is required" : false,
              minLength: field?.minLength
                ? {
                    value: field.minLength,
                    message: `The minimum length is ${field.minLength} characters.`,
                  }
                : undefined,
              maxLength: field?.maxLength
                ? {
                    value: field.maxLength,
                    message: `The maximum length is ${field.maxLength} characters.`,
                  }
                : undefined,
              max: field?.maxValue
                ? {
                    value: field.maxValue,
                    message: `The maximum value for this field is ${field.maxValue}.`,
                  }
                : undefined,
              pattern: field?.pattern
                ? {
                    value: field.pattern,
                    message: "The value of this field is not valid.",
                  }
                : undefined,
            }}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <TextField
                  fullWidth
                  key={field.id}
                  disabled={field.disabled}
                  type={field.textFieldType ?? undefined}
                  value={
                    field.textFieldType === "number"
                      ? +getValues(field.id) === 0 &&
                        String(getValues(field.id)).length === 0
                        ? parseFloat(
                            String(getValues(field.id)).replace(/^0+/, ""),
                          )
                        : +getValues(field.id)
                      : getValues(field.id)
                        ? getValues(field.id)
                        : ""
                  }
                  label={label}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors[field.id] || onChangeError.length > 0}
                  helperText={
                    errors[field.id]?.message
                      ? t(errors[field.id]?.message as string)
                      : onChangeError.length > 0
                        ? t(onChangeError)
                        : null
                  }
                  onChange={(e) => {
                    if (
                      typeof field.onChangeMessage !== "undefined" &&
                      mode === "edit"
                    ) {
                      setOnChangeError(field.onChangeMessage);
                    }

                    onChange(e);
                    if (field.id === "latitude" || field.id === "longitude") {
                      setLatLng({
                        lat: +getValues("latitude"),
                        lng: +getValues("longitude"),
                      });
                    }
                  }}
                  // send value to hook form
                  onBlur={onBlur} // notify when input is touched/blur
                />
              );
            }}
          />
        );
      case "Select":
        return (
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.required ? "This field is required" : false,
            }}
            render={({ field: { onChange, onBlur } }) => (
              <FormControl
                fullWidth
                sx={
                  // Styles for machine edit page: module input and detach button.
                  field.id === "module_id" &&
                  typeof getValues("module_id") !== "undefined"
                    ? {
                        width: "71%",
                      }
                    : {}
                }
              >
                <InputLabel
                  sx={{
                    fontSize: "12px",
                    paddingLeft: "12px",
                  }}
                >
                  {label}
                </InputLabel>
                {warnings.length == 0 ? (
                  <Select
                    sx={{ flex: 1 }}
                    value={getAutoCompleteValue(
                      field.type,
                      getValues,
                      setValue,
                      field,
                      tableData,
                    )}
                    defaultValue={""}
                    error={!!errors[field.id]}
                    key={field.id}
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur}
                    // notify when input is touched/blur
                  >
                    {
                      // Currently the only field that uses hardcoded values is role
                      // Logic is not generic because it assumes there is only a roles object
                      // There is no intended further use and is made as a solution until there is an API to
                      // fetch existing roles. Refactor if a need to use hardcoded values occurs.

                      // WILL BE REFACTORED

                      field.useHardcodedOptions && field.id === "role"
                        ? roles.map(
                            (option: AutoCompleteOption, index: number) => {
                              if (
                                user?.role.type === "owner" &&
                                ["admin", "end_user"].includes(
                                  option.id.toString(),
                                )
                              ) {
                                return;
                              }

                              return (
                                <MenuItem key={index} value={option.id}>
                                  {t(option?.label) ?? null}
                                </MenuItem>
                              );
                            },
                          )
                        : field.useHardcodedOptions &&
                            field.id === "type" &&
                            field.hardcodedOptionsType === "promotionTypes"
                          ? promotionTypes.map(
                              (option: string, index: number) => {
                                return (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              },
                            )
                          : field.useHardcodedOptions && field.id === "currency"
                            ? allCurrencies.map(
                                (option: string, index: number) => {
                                  return (
                                    <MenuItem key={index} value={option}>
                                      {option}
                                    </MenuItem>
                                  );
                                },
                              )
                            : field.useHardcodedOptions &&
                                field.id === "unit_name"
                              ? ingredientUnits.map(
                                  (
                                    option: AutoCompleteOption,
                                    index: number,
                                  ) => {
                                    return (
                                      <MenuItem key={index} value={option.id}>
                                        {t(option.label.toLowerCase())}
                                      </MenuItem>
                                    );
                                  },
                                )
                              : field.useHardcodedOptions &&
                                  field.id === "product_type"
                                ? productTypes.map(
                                    (
                                      option: AutoCompleteOption,
                                      index: number,
                                    ) => {
                                      return (
                                        <MenuItem key={index} value={option.id}>
                                          {t(option.label.toLowerCase())}
                                        </MenuItem>
                                      );
                                    },
                                  )
                                : field.useHardcodedOptions &&
                                    field.id === "flow"
                                  ? flowTypes.map(
                                      (
                                        option: AutoCompleteOption,
                                        index: number,
                                      ) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={option.id}
                                          >
                                            {t(option.label.toLowerCase())}
                                          </MenuItem>
                                        );
                                      },
                                    )
                                  : field.useHardcodedOptions &&
                                      field.hardcodedOptionsType ===
                                        "giftCardsTypes"
                                    ? giftCardType.map(
                                        (option: string, index: number) => (
                                          <MenuItem key={index} value={option}>
                                            {t(option)}
                                          </MenuItem>
                                        ),
                                      )
                                    : field.useHardcodedOptions &&
                                        field.id === "validity_type"
                                      ? giftCardValidPeriod.map(
                                          (option: string, index: number) => (
                                            <MenuItem
                                              key={index}
                                              value={option}
                                            >
                                              {t(option)}
                                            </MenuItem>
                                          ),
                                        )
                                      : getValues(`options.0.${field.id}`)?.map(
                                          (
                                            option: AutoCompleteOption,
                                            index: number,
                                          ) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={
                                                  field.useIdInPayload
                                                    ? option.id
                                                    : option.label
                                                }
                                              >
                                                {t(option?.label)}
                                              </MenuItem>
                                            );
                                          },
                                        )
                    }
                  </Select>
                ) : (
                  <OutlinedInput
                    sx={{ flex: 1 }}
                    placeholder={t(warnings)}
                    readOnly
                  />
                )}
                <FormHelperText sx={{ color: "red" }}>
                  {errors[field.id]?.message
                    ? t(errors[field.id]?.message as string)
                    : null}
                </FormHelperText>
              </FormControl>
            )}
          />
        );
      case "Autocomplete":
      case "MultiAutocomplete":
        return (
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.required ? "This field is required" : false,
            }}
            render={({ field: { onChange } }) => (
              <FormControl fullWidth>
                {warnings.length == 0 ? (
                  <Autocomplete
                    //@ts-ignore
                    value={getAutoCompleteValue(
                      field.type,
                      getValues,
                      setValue,
                      field,
                    )}
                    isOptionEqualToValue={(option, value) =>
                      //@ts-ignore
                      option.label === value
                    }
                    key={field.id}
                    multiple={field.type === "MultiAutocomplete" ? true : false}
                    options={
                      getValues(`options.0.${field.id}`)?.length > 0 &&
                      getValues(`options.0.${field.id}`)
                        ? getValues(`options.0.${field.id}`)
                            .filter(
                              (option: { label: string }) =>
                                option.label !== "Unknown label",
                            )
                            .sort(
                              (a: AutoCompleteOption, b: AutoCompleteOption) =>
                                a.label.localeCompare(b.label),
                            )
                        : []
                    }
                    //@ts-ignore
                    onChange={(
                      _,
                      newValue:
                        | AutoCompleteOption[]
                        | AutoCompleteOption
                        | null,
                    ) => {
                      if (newValue === null) {
                        onChange(null);
                        return;
                      }
                      // Multi auto select will be an array and we need to either map the ids
                      // or the labels depending on what exactly the API requires

                      // If it is not a multi auto select then either send one id or label only
                      if (Array.isArray(newValue) && newValue.length > 0) {
                        let values = newValue.map(
                          (value: AutoCompleteOption) =>
                            field.useIdInPayload ? value!.id : value!.label,
                        );

                        onChange(values);
                      } else
                        onChange(
                          field.useIdInPayload
                            ? (newValue as AutoCompleteOption).id
                            : (newValue as AutoCompleteOption).label,
                        );
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      //@ts-ignore
                      <TextField
                        {...params}
                        key={field.id}
                        label={label}
                        error={!!errors[field.id]}
                        helperText={
                          errors[field.id]?.message
                            ? t(errors[field.id]?.message as string)
                            : null
                        }
                      />
                    )}
                  />
                ) : (
                  <OutlinedInput
                    sx={{ flex: 1 }}
                    placeholder={t(warnings)}
                    readOnly
                  />
                )}
              </FormControl>
            )}
          />
        );
      case "Map":
        return (
          <MapWithMarkerOnClick
            setValue={setValue}
            getChangedLatLng={getChangedLatLng}
          />
        );

      case "AddResourceDialog":
        return (
          <Controller
            control={control}
            name={field.id}
            render={({ field: { onChange } }) => (
              <DynamicDialog
                hideActions={true}
                title={t(field.label as string)}
                divClasses={"ResourceDialog"}
                component={
                  <DynamicForm
                    mode="create"
                    nestedOnSubmit={(data: any) => {
                      // childCreated triggers a reload of the available options and will include
                      // the newly created resource
                      setValue("childCreated", !getValues("childCreated"));
                      onChange(data);
                    }}
                    call={field.autoCompleteRequestType!}
                    callEndPoint={field.autoCompleteRequestEndpoint!}
                    resource={field.dialogResource!}
                    disableNotifications={true}
                    dynamicClass={() =>
                      field.id === "addLocationButton"
                        ? "locationCreateForm nth-3 nth-4 nth-5"
                        : "flexHalfForm"
                    }
                  />
                }
                iconButton={<AddIcon fontSize="medium" htmlColor="#9e9e9e" />}
                openMessage={t(field.label as string) ?? null}
                isOpen={false}
              />
            )}
          />
        );
      case "MdbSettingsForm":
        return <MdbSettingsForm />;
      case "Button":
        return field.id === "detachModuleButton" &&
          typeof getValues("module_id") === "undefined" ? (
          <Fragment />
        ) : (
          <Button
            variant="outlined"
            onClick={() => field.onClick!({ setter: setValue })}
            style={{
              marginBottom: "30px",
              marginLeft: "20px",
            }}
          >
            {t(field.label as string) ?? null}
          </Button>
        );
      case "RadioButton":
        return (
          <Controller
            control={control}
            name={field.id}
            render={({ field: { onChange } }) => (
              <Box>
                <InputLabel
                  sx={{
                    fontSize: "12px",
                    paddingLeft: "12px",
                  }}
                >
                  {label}
                </InputLabel>
                <RadioGroup
                  onChange={onChange}
                  key={field.id}
                  value={getValues(field.id) ? getValues(field.id) : false}
                  row={true}
                  sx={{ marginLeft: "5px" }}
                >
                  {field?.radioOptions?.map(
                    (option: RadioButtonOption, index: number) => (
                      <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={t(option.label)}
                      />
                    ),
                  )}
                </RadioGroup>
              </Box>
            )}
          />
        );
      case "Typography":
        return (
          <Typography
            sx={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "18px",
            }}
            key={field.label}
          >
            {t(field.label as string)}
          </Typography>
        );
      case "PhoneInput":
        return (
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.required ? "This field is required" : false,
            }}
            render={({ field: { onChange } }) => (
              <Box>
                <InputLabel
                  sx={{
                    fontSize: "12px",
                    paddingLeft: "12px",
                  }}
                  error={!!errors[field.id]}
                >
                  {label}
                </InputLabel>
                <PhoneInput
                  inputStyle={{
                    color: "black",
                    backgroundColor: "#E6E3E1",
                  }}
                  // Using PhoneInput's validation message prop for showing required error.
                  isValid={() => {
                    if (errors[field.id]?.message) {
                      return t(errors[field.id]?.message?.toString() ?? "");
                    } else {
                      return true;
                    }
                  }}
                  country="bg"
                  onChange={onChange}
                  value={getValues(field.id) ? getValues(field.id) : undefined}
                />
              </Box>
            )}
          />
        );
      case "IngredientsTable":
        return (
          <RecipeProductIngredients
            editIngredients={getValues("ingredients")?.map((i: any) => ({
              ...i,
              measurement_unit: i.unit_name,
              allergens: i.allergens.map((a: any) => a.name),
            }))}
            companyId={getValues("company_id")}
          />
        );
      case "DatePicker":
        return (
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.required ? "This field is required" : false,
            }}
            render={({ field: { onChange } }) => {
              return (
                <Data
                  range={getValues(field.id)}
                  onChange={onChange}
                  fixedStart={field.fixedStart}
                  futureDatesOnly={field.futureDatesOnly}
                  fieldLabel={field.label}
                  inlineStyle={field.inlineStyle}
                  isDateRanged={field.isDateRanged}
                  {...(mode === "edit"
                    ? field.onEditArgs?.()
                    : field.onCreateArgs?.())}
                />
              );
            }}
          />
        );
      case "Image":
        return (
          <ImageUpload
            setValue={setValue}
            isCropSquared={field?.isCropSquared ?? false}
          />
        );
    }
  };

  const generateFieldsForResource = (
    mode: string,
    getValues: Function,
    setValue: Function,
    formFields: FieldDescriptor[],
    control: Control<FormData>,
    errors: FieldErrors,
    watch: UseFormWatch<FieldValues>,
    tableData?: any,
  ) => {
    let generatedFields: JSX.Element[] = [];
    if (formFields?.length !== 0) {
      formFields?.forEach((fieldDesc: FieldDescriptor) => {
        return generatedFields.push(
          generateField(
            mode,
            fieldDesc,
            getValues,
            setValue,
            //@ts-ignore
            control,
            errors,
            watch,
            tableData,
          )!,
        );
      });
    }

    return generatedFields;
  };

  return { generateField, generateFieldsForResource, fieldToWatch };
};

export default useFieldGenerator;

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";

import * as _ from "lodash";
import moment from "moment";

import FilterData, { FilterType } from "models/filterData.model";
import { Filter } from "models/queryParams.model";
import Company from "models/resources/company.model";
import Location from "models/resources/location.model";
import Machine from "models/resources/machine.model";
import POS from "models/resources/pos.model";
import QR from "models/resources/qr.model";
import apiClient from "services/api";

interface FilterOptions {
  companies: Company[];
  locations: Location[];
  machines: Machine[];
  pos: POS[];
  qr: QR[];
}

interface FitlerProviderProps {
  children: ReactNode;
}

interface FilterContextProps {
  filter: Filter;
  handleFilterChange(...filters: FilterData[]): void;
  options: FilterOptions;
  defaultCompanyId: number | null;
  handleDefaultCompanyIdChange(id: number | null): void;
}

const FilterContext = createContext({} as FilterContextProps);

const useFilterContext = () => useContext(FilterContext);

export const FilterProvider = ({ children }: FitlerProviderProps) => {
  const location = useLocation();
  const { id } = useParams();
  const [filter, setFilter] = useState<Filter>({});
  const [options, setOptions] = useState<FilterOptions>({
    companies: [],
    locations: [],
    machines: [],
    pos: [],
    qr: [],
  });
  const [defaultCompanyId, setDefaultCompanyId] = useState<number | null>(null);
  const lastPageRef = useRef<string>("");

  const handleFilterChange = (...filters: FilterData[]) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      ...filters.reduce(
        // Convert object array to object.
        (obj, item) => Object.assign(obj, { [item.type]: item.value }),
        {},
      ),
    }));
  };

  const handleDefaultCompanyIdChange = (id: number | null) => {
    setDefaultCompanyId(id);
  };

  const handleOptionsFetch = async (url: string) => {
    await apiClient({
      url,
      method: "get",
      queryParams: {
        pagination: { page: 1, perPage: Number.MAX_SAFE_INTEGER },
        sort: { field: "id", order: "DESC" },
      },
    }).then((response) => {
      setOptions((oldState) => ({
        ...oldState,
        [url]: response.data,
      }));
    });
  };

  useEffect(() => {
    const currentPageComponent: string =
      location.pathname.split("/account/")[1];

    (async () => {
      // Fetch filter options.
      if (options.companies.length === 0) {
        await handleOptionsFetch("companies");
      }

      switch (currentPageComponent) {
        case "machines":
        case "pos":
        case "qr-payment-codes":
          if (options.locations.length === 0) {
            await handleOptionsFetch("locations");
          }
          break;
        case "orders":
        case "transactions":
          if (options.machines.length === 0) {
            await handleOptionsFetch("machines");
          }

          if (options.pos.length === 0) {
            await handleOptionsFetch("pos");
          }

          if (options.qr.length === 0) {
            await handleOptionsFetch("qr");
          }

          break;
      }
    })();

    // Doesn't execute in resource details pages to prevent loosing filter when returning back to the table page.
    if (typeof id !== "undefined") return;

    if (lastPageRef.current === currentPageComponent) return;
    // Used for keeping last selected filters on resource details page backing to table page.
    lastPageRef.current = currentPageComponent;

    // TODO: Remove deleted filter when BE fix issue around machines, pos and qrs without deleted: false.
    let defaultPageFilter: Filter = ["modules"].some((s: string) =>
      currentPageComponent.includes(s),
    )
      ? { resourceDeleted: true }
      : {};

    defaultPageFilter =
      [
        "dashboard",
        "locations",
        "machines",
        "pos",
        "qr-payment-codes",
        "transactions",
        "orders",
        "users",
        "modules",
        "promotions",
        "invoices",
      ].some((s: string) => currentPageComponent.includes(s)) &&
      defaultCompanyId !== null
        ? { ...defaultPageFilter, companyIds: [defaultCompanyId] }
        : { ...defaultPageFilter };

    defaultPageFilter = [
      "dashboard",
      "orders",
      "transactions",
      "invoices",
    ].some((s: string) => currentPageComponent.includes(s))
      ? {
          ...defaultPageFilter,
          dateRange: {
            startDate: moment()
              .subtract(1, "month")
              .startOf("day")
              .toISOString(),
            endDate: moment().endOf("day").toISOString(),
          },
        }
      : { ...defaultPageFilter };

    setFilter(defaultPageFilter);
  }, [location.pathname]);

  useEffect(() => {
    if (defaultCompanyId === null) {
      // Removes default company filter from other filters.
      setFilter((oldFilter) => {
        const { companyIds, issuerCompanyIds, ...rest } = oldFilter;

        return rest;
      });
      return;
    }

    if (!location.pathname.includes("invoices")) {
      handleFilterChange({
        type: FilterType.Company,
        value: [defaultCompanyId],
      });

      return;
    }
  }, [defaultCompanyId]);

  return (
    <FilterContext.Provider
      value={{
        filter,
        handleFilterChange,
        options,
        defaultCompanyId,
        handleDefaultCompanyIdChange,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default useFilterContext;

import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  DialogActions,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import DatePickerComp from "components/DatePicker/DatePicker";
import { CredentialsToken } from "models/resources/company.model";

import useApiCredentialsForm from "./useApiCredentialsForm";

interface ApiCredentialsFormProps {
  projectId:
    | number
    | null /* Used as a request param for create/edit functionallity. */;
  resourceId?:
    | number
    | null /* Used in edit form for the current token id, passed from the CustomTable component. */;
  updateTokens(
    updatedToken: CredentialsToken,
  ): void /* Updates token table after successfully creating a new token or updating existing one. */;
  addProjectId(
    newProjectId: number | null,
  ): void /* Sets project id after successfully creating the first token for current company. Id is used for token delete request. */;
  addProjectKey(
    projectKey: string,
  ): void /* Sets project key after successfully creating the first token for current company. */;
}

const ApiCredentialsForm = ({
  projectId,
  resourceId = null,
  updateTokens,
  addProjectId,
  addProjectKey,
}: ApiCredentialsFormProps) => {
  const { t } = useTranslation();
  const {
    isActive,
    isSandbox,
    handleIsActiveOnChange,
    handleIsSandboxOnChange,
    description,
    callbackUrl,
    handleFormValuesOnChange,
    validUntil,
    handleValidUntilOnChange,
    isDatePickerOpen,
    handleDateRangeOnClick,
    handleSubmitOnClick,
  } = useApiCredentialsForm(
    projectId,
    resourceId,
    updateTokens,
    addProjectId,
    addProjectKey,
  );

  const isEditForm = resourceId !== null;

  return (
    <Box style={{ overflow: "visible" }}>
      <div style={{ marginBottom: "7px" }}>
        <FormControlLabel
          sx={{
            display: "inline",
          }}
          label={t("Active")}
          control={
            <Switch
              name={t("active")}
              checked={isActive}
              onChange={handleIsActiveOnChange}
            />
          }
        />
        {!isEditForm && (
          <FormControlLabel
            sx={{
              display: "inline",
            }}
            label={t("Sandbox")}
            control={
              <Switch
                name={"sandbox"}
                checked={isSandbox}
                onChange={handleIsSandboxOnChange}
              />
            }
          />
        )}
      </div>
      <TextField
        fullWidth
        name={t("description")}
        label={t("Description")}
        value={description}
        onChange={handleFormValuesOnChange}
      />

      <TextField
        fullWidth
        name={t("callbackUrl")}
        label={t("Callback url")}
        value={callbackUrl}
        onChange={handleFormValuesOnChange}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography>{t("Valid until")}</Typography>
        <DatePickerComp
          onChange={handleValidUntilOnChange}
          range={[validUntil as Date, null]}
          inlineStyle={{ marginLeft: "10px" }}
          futureDatesOnly
        />
      </div>

      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          onClick={handleSubmitOnClick}
          sx={{
            boxShadow: "none",
          }}
        >
          {t(!isEditForm ? t("Create") : t("Save"))}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default ApiCredentialsForm;

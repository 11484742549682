import React, { useState } from "react";
import { forwardRef } from "react";
import { useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { bg } from "date-fns/locale/bg";
import { enGB } from "date-fns/locale/en-GB";

import { formatDate } from "./utils";

registerLocale("bg", bg);
registerLocale("en", enGB);

const rangeDefault = [new Date(), new Date().setDate(new Date().getDate() + 7)];

interface DatePickerProps {
  range?: (number | Date | null)[];
  onChange: (dates: Date[] | Date) => void;
  fixedStart?: boolean;
  fieldLabel?: string;
  futureDatesOnly?: boolean;
  futureDatesFromStart?: boolean;
  inlineStyle?: object;
  isDateRanged?: boolean;
  isCentered?: boolean;
}
interface CustomDatePickerProps {
  onClick?: () => void;
}

type DatePickerCommonFilter = {
  dropdownMode: "scroll" | "select" | undefined;
  showYearDropdown: boolean;
  showMonthDropdown: boolean;
  minDate?: Date | null | undefined;
};

export default function DatePickerComp({
  range = rangeDefault,
  onChange,
  fixedStart,
  fieldLabel,
  futureDatesOnly,
  futureDatesFromStart,
  inlineStyle,
  isDateRanged = false,
  isCentered = false,
}: DatePickerProps) {
  const { i18n } = useTranslation();
  const [startDate, setStartDate] = useState<number | Date | null>(range[0]);
  const [endDate, setEndDate] = useState<number | Date | null>(range[1]);
  const { t } = useTranslation();
  const theme = useTheme();
  const labelColorMUI = theme.palette.text.secondary;

  useEffect(() => {
    setStartDate(range[0]);
    setEndDate(range[1]);
  }, [range]);

  const onChangeRangedDates = (dates: any) => {
    if (fixedStart) {
      setEndDate(dates);
      onChange([startDate, dates]);
    } else {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      onChange(dates as Date[]);
    }
  };

  const onChangeSingleDate = (date: Date) => {
    setStartDate(date as Date);
    onChange(date as Date);
  };

  const getMinDate = (): Date | null | undefined => {
    if (futureDatesFromStart) return startDate as Date;
    else if (futureDatesOnly) return new Date();
    return null;
  };

  const filterDate: DatePickerCommonFilter = {
    dropdownMode: "select",
    showYearDropdown: true,
    showMonthDropdown: true,
    minDate: getMinDate(),
  };

  const CustomRangedInput = forwardRef<
    HTMLButtonElement,
    CustomDatePickerProps
  >((props, _ref) => {
    const { onClick } = props;

    return (
      <Button onClick={onClick}>
        <Typography>
          {formatDate(startDate!)} - {endDate ? formatDate(endDate!) : ""}
        </Typography>
      </Button>
    );
  });

  const CustomSingleInput = forwardRef<
    HTMLButtonElement,
    CustomDatePickerProps
  >((props, _ref) => {
    const { onClick } = props;

    return (
      <Button onClick={onClick}>
        <Typography>{formatDate(startDate ?? new Date())}</Typography>
      </Button>
    );
  });

  return (
    <div style={{ position: "relative" }}>
      <Typography
        className="label"
        style={{
          color: labelColorMUI,
          transform: "translate(14px, -9px) scale(0.75)",
          top: "-9px",
          transformOrigin: "top left",
          position: "absolute",
        }}
      >
        {t(fieldLabel ?? "")}
      </Typography>
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.06)",
          borderRadius: "4px",
          height: "36px",
          display: "flex",
          justifyContent: isCentered ? "center" : "left",
          alignItems: "center",
          ...inlineStyle,
        }}
      >
        {isDateRanged ? (
          <DatePicker
            selectsRange={!fixedStart}
            selected={(startDate as Date) ?? new Date()}
            startDate={startDate as Date}
            endDate={endDate as Date}
            customInput={<CustomRangedInput />}
            onChange={onChangeRangedDates}
            locale={i18n.language}
            {...filterDate}
          />
        ) : (
          <DatePicker
            selected={(startDate as Date) ?? new Date()}
            onChange={onChangeSingleDate}
            selectsRange={false}
            customInput={<CustomSingleInput />}
            locale={i18n.language}
            {...filterDate}
          />
        )}
      </div>
    </div>
  );
}

import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import ContentLayout from "components/layouts/ContentLayout";

import useProductDetails from "./useProductDetails";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { productData, setProductData, image, setRefetchFlag } =
    useProductDetails();

  if (productData === null) return <Fragment />;

  const fields = [
    {
      value: productData.name,
      label: "Name",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.display_name,
      label: "Product name",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.currency,
      label: "Currency",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.company.display_name || productData.company.name,
      id: productData.company_id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: productData.price,
      label: "Price",
      type: "InfoText",
      show: true,
    },
    {
      value: productData.description,
      label: "Description",
      type: "DescriptiveInfoText",
      show: true,
    },
    {
      value: t(productData.product_type),
      label: "Product type",
      type: "InfoText",
      show: true,
    },
    {
      value:
        productData?.machines?.length === 0
          ? "---"
          : productData?.machines?.map((m) => m.name).join(", "),
      label: "Machines",
      type: "InfoText",
      show: true,
    },
    {
      value: image,
      label: "Image",
      type: "ImageFile",
      inlineStyle: { width: "400px", height: "200px" },
      show: true,
    },
    {
      value: productData.ingredients,
      label: "Ingredients",
      type: "Table",
      show: true,
      headers: [t("Name"), t("Quantity"), t("Measurement"), t("Allergens")],
      columns: productData.ingredients?.map((e, i) => {
        return (
          <TableRow
            key={`${i}-${e.name}`}
            hover
            sx={{ cursor: "pointer" }}
            onClick={(event: any) => {
              if (event.target.nodeName === "TD")
                navigate(`/account/vending/ingredients/${e.id}`);
            }}
          >
            <TableCell sx={{ padding: "5px 0 0 5px" }}>{e.name}</TableCell>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>{e.quantity}</TableCell>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>
              {t(e.unit_name)}
            </TableCell>
            <TableCell sx={{ padding: "5px 0 0 5px" }}>
              <p style={{ margin: 0 }}>
                {e.allergens.map((allergen) => allergen.name).join(", ")}
              </p>
            </TableCell>
          </TableRow>
        );
      }),
    },
  ];

  return (
    <ContentLayout
      title={`${t("Products")} > ${productData?.name ?? ""}`}
      filters={false}
    >
      <CustomDetailsPage
        data={productData}
        setData={(v) => {
          setProductData(v);
          setRefetchFlag((old) => !old);
        }}
        resource={"product"}
        call={"products"}
        fields={fields}
        isDeletable={productData.machines.length === 0}
      />
    </ContentLayout>
  );
};

export default ProductDetails;

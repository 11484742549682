import { useState } from "react";
import { useTranslation } from "react-i18next";

import * as _ from "lodash";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SignalWifiStatusbarNullIcon from "@mui/icons-material/SignalWifiStatusbarNull";
import { Box, Grid, Typography } from "@mui/material";

import LineChart from "components/Chart/LineChart";
import MachinePowerChart from "components/Chart/MachinePowerChart";
import ModuleSignal from "components/ModuleSignal";
import {
  CustomTable,
  Column,
  TableSettings,
  CellValue,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import useAuthContext from "context/AuthContext";
import useFilterContext from "context/FilterContext";
import { FilterType } from "models/filterData.model";
import { RequestPagination } from "models/queryParams.model";
import QueryParams from "models/queryParams.model";
import Machine from "models/resources/machine.model";
import Transaction from "models/resources/transaction.model";
import { ResponseDataTable } from "models/responseData.model";
import apiClient from "services/api";

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { filter } = useFilterContext();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [machineStatuses, setMachineStatuses] = useState({ up: 0, down: 0 });
  const [tableData, setTableData] = useState<ResponseDataTable<Machine[]>>();

  const getTransactionsData = async (pagination: RequestPagination) => {
    const { resourceDeleted, searchQuery, ...queryFilter } = filter;

    await apiClient<Transaction[]>({
      url: "transactions",
      queryParams: {
        filter: queryFilter,
        pagination: pagination,
      },
      method: "get",
    }).then((response) => setTransactions(response.data));
  };

  const setMachineStatusesData = async (
    RequestPagination: RequestPagination,
  ) => {
    let { companyIds, ...rest } = filter;

    const machines: Machine[] = await apiClient<Machine[]>({
      url: "machines",
      method: "get",
      queryParams: {
        filter: { companyIds },
        pagination: RequestPagination,
      },
    }).then((response) => response.data);

    const getMachinesCountByStatus = (status: string): number => {
      return machines?.filter(
        (machine) =>
          typeof machine.module !== "undefined" &&
          machine.module !== null &&
          machine.module.state === status,
      ).length;
    };

    const upCount: number = getMachinesCountByStatus("up");
    const downCount: number = getMachinesCountByStatus("down");

    setMachineStatuses({ up: upCount, down: downCount });
  };

  const getDataForTable = async (
    pagination: RequestPagination,
    setDataLoading: any,
  ) => {
    const { resourceDeleted, dateRange, searchQuery, ...qrFilters } = filter;

    if (typeof dateRange === "undefined") return;

    await apiClient<Machine[]>({
      url: "/machines/report/transaction-date",
      queryParams: {
        filter: qrFilters,
        pagination: pagination,
        sort: { field: "id", order: "DESC" },
        date_range: {
          start_date: dateRange.startDate,
          end_date: dateRange.endDate,
        },
      },
      method: "get",
    }).then((response) => {
      setDataLoading(false);
      setTableData(response);
    });
  };

  const setData = async (queryParams: QueryParams, setDataLoading: any) => {
    setDataLoading(true);
    getTransactionsData(queryParams?.pagination!);
    setMachineStatusesData(queryParams?.pagination!);
    getDataForTable(queryParams?.pagination!, setDataLoading);
  };

  const columns: Column<Machine>[] = [
    {
      label: t("Machine Name"),
      name: "machineName",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      width: "25%",
      minWidth: "200px",
    },
    {
      label: t("Signal Strength"),
      name: "moduleS",
      getData: (data): CellValue =>
        data.module?.signal_strength ? (
          <ModuleSignal
            signal={data.module?.signal_strength ?? null}
            noLabel={true}
          />
        ) : (
          <SignalWifiStatusbarNullIcon />
        ),
      show: () => {
        return true;
      },
      width: "15%",
      minWidth: "200px",
      align: "center",
    },
    {
      label: t("State"),
      name: "moduleState",
      getData: (data): CellValue =>
        `${data.module?.state}` === "up" ? (
          <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
        ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "machine.module.state" },
      width: "15%",
    },
    {
      label: t("Amount"),
      name: "amount",

      getData: (data): CellValue => (
        <Box>
          <Typography
            sx={{ fontSize: "0.8em", fontWeight: "bold" }}
            component="span"
            p={1}
          >
            {data.currency}
          </Typography>
          <Typography component="span">
            {Number(data?.total_amount).toFixed(
              data.currency.toUpperCase() === "AVAX" ? 9 : 2,
            )}
          </Typography>
        </Box>
      ),
      show: () => {
        return true;
      },
      width: "10%",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  if (user?.role?.type === "operator") {
    return (
      <ContentLayout title={t("AVERATO Administration Platform")}>
        <Box>
          <MachinePowerChart
            up={machineStatuses.up}
            down={machineStatuses.down}
          />
        </Box>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title={t("AVERATO Administration Platform")} isDateRanged>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <LineChart
            dataArray={transactions}
            dataType="transaction"
            dateRange={filter?.dateRange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MachinePowerChart
            up={machineStatuses.up}
            down={machineStatuses.down}
          />
        </Grid>
        {tableData?.data?.length !== 0 ? (
          <Grid item xs={12}>
            <CustomTable
              title={t("Machines")}
              notCSV={true}
              tableData={tableData}
              fetchPaginatedData={setData}
              columns={columns}
              tableOptions={tableOptions}
              clickableRow={false}
              isSearchable={false}
            />
          </Grid>
        ) : null}
      </Grid>
    </ContentLayout>
  );
};

export default Dashboard;

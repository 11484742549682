import { useState } from "react";

const useDialogShowState = () => {
  const [show, setShow] = useState<boolean>(false);

  const handleShowStateChange = () => setShow((oldState) => !oldState);

  return {
    show,
    handleShowStateChange,
  };
};

export default useDialogShowState;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  TextField,
  Typography,
  getInitColorSchemeScript,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import useMachineDetails from "../useMachineDetails";
import ConfirmDenyDialog from "components/Forms/custom/Dialogs/ConfirmDenyDialog";
import DynamicDialog from "components/layouts/Dialog";
import { IngredientBase, ProductType } from "models/resources/ingredient.model";

const maxQuantity = Number.MAX_SAFE_INTEGER;

interface IngredientCardProps {
  id: number;
  name: string;
  unitName: string;
  capacity: string;
  rechargeQuantity: string;
  setMachineIngredients: any;
  machineIngredients: IngredientBase[] | null;
  product_type: ProductType.packaged | ProductType.recipe;
  quantity: string;
}

interface FieldProps {
  id: number;
  capacity: number;
  quantity: number;
  recharge_quantity: number;
}

const IngredientCard = ({
  name,
  unitName,
  capacity,
  setMachineIngredients,
  rechargeQuantity,
  machineIngredients,
  product_type,
  quantity,
  id,
}: IngredientCardProps) => {
  const { t } = useTranslation();

  const [fields, setFields] = useState<FieldProps>({
    capacity: parseInt(capacity),
    recharge_quantity: parseInt(rechargeQuantity),
    quantity: parseInt(quantity),
    id,
  });

  const [newFields, setNewFields] = useState<FieldProps>(fields);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isDeleteDialogOpen, showDeleteDialog] = useState<boolean>(false);

  const navigate = useNavigate();
  const isRecipe = product_type === ProductType.recipe;

  useEffect(() => {
    const updatedFields = {
      capacity: parseInt(capacity),
      recharge_quantity: parseInt(rechargeQuantity),
      quantity: parseInt(quantity),
      id,
    };

    setFields(updatedFields);
    setNewFields(updatedFields);
  }, [machineIngredients]);

  const getQuantityNumber = (quantity: string) => {
    if (quantity == "") return 0;
    const parsedQuantity = parseInt(quantity);
    return parsedQuantity >= maxQuantity ? maxQuantity : parsedQuantity;
  };

  const { attachIngredient, detachIngredients } = useMachineDetails();
  return (
    <Box sx={{ width: "250px" }}>
      <Card
        variant="outlined"
        style={{
          marginRight: "20px",
          marginBottom: "20px",
        }}
      >
        <DynamicDialog
          title={t("Are you sure about deleting this ingredient?")}
          component={
            <ConfirmDenyDialog
              onConfirm={() => {
                detachIngredients(id, setMachineIngredients);
                showDeleteDialog(false);
              }}
              onDeny={() => {
                showDeleteDialog(false);
              }}
            />
          }
          openState={[isDeleteDialogOpen, showDeleteDialog]}
          hide
          hideActions
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              <Typography>{t("Ingredient")}</Typography>{" "}
              <h4 style={{ margin: 0, wordBreak: "break-all" }}>{name}</h4>
            </div>
            <div>
              {isRecipe && (
                <DeleteForeverIcon
                  color="error"
                  sx={{
                    fontSize: "xx-large",
                    display: "inherit",
                    cursor: "pointer",
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                  }}
                  onClick={() => {
                    showDeleteDialog(true);
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <Typography>{t("Capacity")}</Typography>
            <div
              style={{
                position: "relative",
              }}
            >
              <TextField
                value={isEditing ? newFields.capacity : fields.capacity}
                fullWidth
                disabled={true}
              />
              <p
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "6px",
                  margin: 0,
                }}
              >
                {t(unitName)}
              </p>
            </div>
          </div>
          <div>
            <Typography>{t("Recharge Quantity")}</Typography>
            <div
              style={{
                position: "relative",
              }}
            >
              <TextField
                sx={{ input: { paddingRight: "45px" } }}
                fullWidth
                value={
                  isEditing
                    ? newFields.recharge_quantity
                    : fields.recharge_quantity
                }
                disabled={!isEditing}
                onChange={(e) => {
                  setNewFields((_: any) => ({
                    ...newFields,
                    recharge_quantity: getQuantityNumber(e.target.value),
                  }));
                }}
              />
              <p
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "6px",
                  margin: 0,
                }}
              >
                {t(unitName)}
              </p>
            </div>
          </div>
          <div>
            <Typography>{t("Quantity")}</Typography>
            <div
              style={{
                position: "relative",
              }}
            >
              <TextField
                sx={{ input: { paddingRight: "45px" } }}
                fullWidth
                value={isEditing ? newFields.quantity : fields.quantity}
                disabled={!isEditing}
                onChange={(e) => {
                  setNewFields((_: any) => ({
                    ...newFields,
                    quantity: getQuantityNumber(e.target.value),
                  }));
                }}
              />
              <p
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "6px",
                  margin: 0,
                }}
              >
                {t(unitName)}
              </p>
            </div>
          </div>
          <div>
            {!isEditing && isRecipe && (
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                sx={{ marginTop: "15px" }}
                onClick={async () => {
                  if (await attachIngredient(newFields)) {
                    setFields(newFields);
                    setEditing(false);
                  }
                  navigate(`/account/vending/ingredients/${id}`);
                }}
              >
                {t("View")}
              </Button>
            )}
            {isEditing ? (
              <>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: "15px" }}
                  onClick={async () => {
                    if (await attachIngredient(newFields)) {
                      setFields(newFields);
                      setEditing(false);
                    }
                  }}
                >
                  {t("Update")}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  sx={{ marginTop: "15px" }}
                  onClick={() => {
                    setNewFields(fields);
                    setEditing(false);
                  }}
                >
                  {t("Cancel")}
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                fullWidth
                sx={{ marginTop: "15px" }}
                onClick={() => setEditing(true)}
              >
                {t("Edit")}
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default IngredientCard;

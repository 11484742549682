import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Box, Button, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import useMachineDetails from "../useMachineDetails";
import ConfirmDenyDialog from "components/Forms/custom/Dialogs/ConfirmDenyDialog";
import DynamicDialog from "components/layouts/Dialog";
import { ProductBase } from "models/resources/product.model";

interface ProductsCardProps {
  id: number;
  setMachineProducts: Dispatch<SetStateAction<ProductBase[] | null>>;
  name: string;
  display_name: string;
  price: string;
}

const ProductsCard = ({
  id,
  setMachineProducts,
  name,
  display_name,
  price,
}: ProductsCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isDeleteDialogOpen, showDeleteDialog] = useState<boolean>(false);

  const { detachProduct } = useMachineDetails();

  return (
    <Box sx={{ width: "250px" }}>
      <DynamicDialog
        title={t("Are you sure about deleting this product?")}
        component={
          <ConfirmDenyDialog
            onConfirm={() => {
              detachProduct(id, setMachineProducts);
              showDeleteDialog(false);
            }}
            onDeny={() => {
              showDeleteDialog(false);
            }}
          />
        }
        openState={[isDeleteDialogOpen, showDeleteDialog]}
        hide
        hideActions
      />
      <Card
        variant="outlined"
        style={{
          marginRight: "20px",
          marginBottom: "20px",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <div style={{ position: "relative" }}>
              <Typography>{t("Name")}</Typography>{" "}
              <h4 style={{ margin: 0, wordBreak: "break-all" }}>{name}</h4>
            </div>
            <div>
              <DeleteForeverIcon
                color="error"
                sx={{
                  fontSize: "xx-large",
                  display: "inherit",
                  cursor: "pointer",
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                }}
                onClick={() => {
                  showDeleteDialog(true);
                }}
              />
            </div>
          </div>
          <div
            style={{
              position: "relative",
            }}
          >
            <Typography>{t("Display name")}</Typography>
            <TextField value={display_name} disabled={true} />
          </div>
          <div>
            <Typography>{t("Price")}</Typography>
            <TextField value={price} disabled={true}></TextField>
          </div>
          <div>
            <Button
              variant="outlined"
              sx={{ width: "100%", marginTop: "15px" }}
              onClick={(event: any) => {
                navigate(`/account/vending/products/${id}`);
              }}
            >
              {t("Preview")}
            </Button>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProductsCard;

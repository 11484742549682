import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SignalWifiStatusbarNullIcon from "@mui/icons-material/SignalWifiStatusbarNull";
import { Box, Typography } from "@mui/material";

import { CustomDetailsPage } from "components/DetailsPage/DetailsPage";
import DynamicForm from "components/Forms/generic/DynamicForm";
import IngredientForm from "components/IngredientForm";
import MappingScheme from "components/MappingScheme";
import ModuleSignal from "components/ModuleSignal";
import ContentLayout from "components/layouts/ContentLayout";
import DynamicDialog from "components/layouts/Dialog";
import useDialogShowState from "hooks/useDialogShowState";
import { FieldDescriptor } from "models/fieldsEntities.model";
import Product from "models/resources/product.model";
import constants from "styles/scss/constants.module.scss";

import ProductAtachForm from "./ProductAtachForm";
import IngredientCard from "./custom/IngredientCard";
import ProductsCard from "./custom/ProductsCard";
import useMachineDetails from "./useMachineDetails";
import { createSchemeMatrix } from "./utils";

const MachineDetails = () => {
  const { t } = useTranslation();
  const {
    machineData,
    setMachineData,
    machineProducts,
    setMachineProducts,
    machineIngredients,
    setMachineIngredients,
    rechargeQuantities,
    productType,
    showEditDialog,
    selectedEditIngredient,
    handleEditIngredientChange,
    buttonProductMappers,
    setShowEditDialog,
    attachProduct,
    attachIngredient,
    createSignalMapping,
    image,
    setRefechtFlag,
  } = useMachineDetails();
  const { show, handleShowStateChange } = useDialogShowState();
  const capacityEditDialog = useDialogShowState();

  let fields: FieldDescriptor[] = [
    {
      value: machineData?.name,
      label: "Machine name",
      type: "InfoText",
      show: true,
    },
    {
      value: machineData?.pin,
      label: "Pin",
      type: "TextField",
      show: true,
    },
    {
      value: machineData?.minimum_payment_amount,
      label: t("Minimal transaction sum"),
      type: "InfoText",
      show: true,
    },
    {
      value: t(machineData?.type ?? ""),
      label: "Machine Type",
      type: "InfoText",
      show: true,
    },
    {
      value: machineData?.currency,
      label: "Currency",
      type: "InfoText",
      show: true,
    },
    {
      value: `${machineData?.location.name}, ${machineData?.location.address}`,
      id: machineData?.location_id,
      label: "Location",
      type: "Link",
      route: "locations",
      show: true,
    },
    {
      value: machineData?.company.display_name || machineData?.company.name,
      id: machineData?.company?.id,
      label: "Company",
      type: "Link",
      route: "companies",
      show: true,
    },
    {
      value: machineData?.module?.serial,
      id: machineData?.module?.id,
      label: "Module Serial",
      type: "Link",
      route: "modules",
      show: true,
    },
    {
      value: machineData?.entity_category?.name,
      label: "Entity Category",
      type: "InfoText",
      show: true,
    },
    {
      value: machineData?.module?.state ? machineData?.module?.serial : "---",
      label: "QR code",
      type: machineData?.module?.state ? "QR" : "TextField",
      show: true,
    },
    {
      value: machineData?.module?.signal_strength ? (
        <ModuleSignal
          signal={machineData?.module?.signal_strength ?? null}
          noLabel={true}
        />
      ) : (
        <SignalWifiStatusbarNullIcon />
      ),
      label: "Module Signal Strength",
      type: "Icon",
      show: true,
    },
    {
      value: machineData?.machine_blueprint?.name,
      label: "Blueprint",
      type: "Link",
      route: "vending/blueprints",
      id: machineData?.machine_blueprint?.id,
      show: true,
    },
    {
      value: t(machineData?.product_type!),
      label: t("ProductType"),
      type: "InfoText",
      show: true,
    },
    {
      value:
        machineData?.module?.state === "up" ? (
          <FiberManualRecordIcon sx={{ color: "#4CAF50" }} />
        ) : (
          <FiberManualRecordIcon sx={{ color: "#FF6347" }} />
        ),
      label: "Module State",
      type: "Icon",
      show: true,
    },
    {
      value: machineData?.comments,
      label: "Comments",
      type: "DescriptiveInfoText",
      show: true,
    },
    {
      value: image,
      label: "Image",
      type: "ImageFile",
      inlineStyle: {
        width: "400px",
        height: "400px",
      },
      show: true,
    },

    {
      type: "CustomComponent",
      value: (
        <DynamicDialog
          isOpen={false}
          openMessage="Map products to buttons"
          title="Scheme"
          hideActions
          component={
            <Fragment>
              <MappingScheme
                label="product"
                mappingOptions={machineData?.products as Product[]}
                rowsCount={machineData?.machine_blueprint?.number_of_rows ?? 0}
                columnsCount={
                  machineData?.machine_blueprint?.number_of_columns ?? 0
                }
                signalMatrix={createSchemeMatrix(
                  machineData?.machine_blueprint?.number_of_rows ?? 0,
                  machineData?.machine_blueprint?.number_of_columns ?? 0,
                  machineData?.machine_blueprint?.machine_blueprint_signal_mappings.map(
                    (sm) => ({
                      row: sm.row,
                      column: sm.column,
                      value: sm.button_signal,
                      product_id: buttonProductMappers?.find(
                        (bpm) => bpm.row === sm.row && bpm.column === sm.column,
                      )?.product_id,
                    }),
                  ) ?? [],
                )}
                onSubmit={createSignalMapping}
                // TODO: Add copy machine functionallity in the future.
                // customComponent={
                //   <DynamicDialog
                //     buttonSx={{
                //       marginBottom: "10px",
                //       marginLeft: "auto",
                //       display: "flex",
                //     }}
                //     isOpen={machineCopyDialog.show}
                //     openMessage="Copy from machine"
                //     title="Copy from machine"
                //     hideActions
                //     onClick={machineCopyDialog.handleShowStateChange}
                //     onCancel={machineCopyDialog.handleShowStateChange}
                //     component={
                //       <CopySchemeForm
                //       productType={productType}
                //         onSubmit={(id) => {
                //           copyMachineScheme(id);
                //           machineCopyDialog.handleShowStateChange();
                //         }}
                //       />
                //     }
                //   />
                // }
              />

              {buttonProductMappers
                .filter(
                  (bpm, index, self) =>
                    index ===
                    self.findIndex((t) => t.product_id === bpm.product_id),
                )
                .sort((a, b) => {
                  if (a.product_id < b.product_id) return -1;
                  if (a.product_id > b.product_id) return 1;
                  return 0;
                })
                .map((bpm) => (
                  <div>{`${bpm.product_id} - ${bpm.product.display_name}`}</div>
                ))}
            </Fragment>
          }
        />
      ),
      label: "",

      show: true,
    },
    {
      type: "BlankSpace",
      label: "",
      show: true,
      value: "",
      inlineStyle: { height: "10px" },
    },
    {
      type: "Button",
      label: "",
      show: true,
      value: t("Recharge Quantities"),
      text: "Are you sure you want to recharge the machine quantities?",
      onClick: () => rechargeQuantities(),
    },
  ];

  const productFields: FieldDescriptor[] = [
    {
      type: "Wrap",
      show: true,
      label: "",
      value: (machineProducts ?? [])
        .sort((machineProductA, machineProductB) =>
          machineProductA.name.localeCompare(machineProductB.name),
        )
        .map((product, idx) => {
          return (
            <ProductsCard
              id={product.id}
              key={idx}
              setMachineProducts={setMachineProducts}
              name={product.name}
              display_name={product.display_name}
              price={product.price}
            />
          );
        }),
    },
  ];
  const ingredientFields: FieldDescriptor[] = [
    {
      type: "Wrap",
      show: true,
      label: "",
      value: (machineIngredients ?? [])
        .sort((ingredientA, ingredientB) =>
          ingredientA.name.localeCompare(ingredientB.name),
        )
        .map((ingredient, idx) => {
          return (
            <IngredientCard
              id={ingredient.id}
              key={idx}
              machineIngredients={machineIngredients}
              setMachineIngredients={setMachineIngredients}
              name={ingredient.name}
              unitName={ingredient.unit_name}
              product_type={ingredient.product_type}
              capacity={ingredient.capacity}
              rechargeQuantity={ingredient.recharge_quantity}
              quantity={ingredient.quantity}
            />
          );
        }),
    },
  ];

  enum DialogType {
    PRODUCT = "product",
    INGREDIENT = "ingredient",
  }

  return (
    <ContentLayout
      title={`${t("Machines")} > ${machineData?.name ?? ""}`}
      filters={false}
    >
      {showEditDialog?.display && (
        <DynamicDialog
          hideActions={true}
          title={t(
            `Edit ${
              showEditDialog?.type === DialogType.PRODUCT
                ? "Product"
                : "Ingredient"
            }`,
          )}
          key={`edit-${showEditDialog?.editId}`}
          component={
            <DynamicForm
              key={showEditDialog?.editId}
              isResourceListedInTable={true}
              mode="edit"
              resource={
                showEditDialog?.type === DialogType.PRODUCT
                  ? productType === "packaged"
                    ? "packet-product"
                    : "recipe-product"
                  : DialogType.INGREDIENT
              }
              call={
                showEditDialog?.type === DialogType.PRODUCT
                  ? "products"
                  : "ingredients"
              }
              dynamicClass={() =>
                `edit-form__${
                  showEditDialog?.type === DialogType.PRODUCT
                    ? "product"
                    : "ingredient"
                }`
              }
              editId={showEditDialog?.editId}
              exitAction={setShowEditDialog}
              updateData={setMachineData}
              tableData={machineData}
            />
          }
          isOpen={true}
          hideButton={true}
          exitAction={setShowEditDialog}
        />
      )}

      <CustomDetailsPage
        data={machineData}
        setData={(v) => {
          setMachineData(v);
          setRefechtFlag((old) => !old);
        }}
        resource={"machine"}
        call={"machines"}
        fields={fields}
      />
      <div style={{ height: "20px" }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h3"
          component="header"
          sx={{
            fontFamily: constants.semiBold,
            fontSize: "24px",
            lineHeight: "36px",
            marginBottom: "5px",
          }}
        >
          {t("Products")}
        </Typography>
        <DynamicDialog
          buttonSx={{
            margin: "20px 0 20px 0",
            display: "flex",
          }}
          title={t("Attach products")}
          openMessage={t("Attach products")}
          isOpen={show}
          hideActions
          onClick={handleShowStateChange}
          onCancel={handleShowStateChange}
          component={
            <ProductAtachForm
              productType={productType || "packaged"}
              companyId={machineData?.company_id ? +machineData.company_id : 0}
              attachedList={machineProducts ?? []}
              attachProducts={attachProduct}
              onSubmit={handleShowStateChange}
            />
          }
        />
      </div>
      {machineProducts?.length != 0 ? (
        <CustomDetailsPage
          data={machineData}
          setData={(v) => {
            setMachineData(v);
            setRefechtFlag((old) => !old);
          }}
          resource={""}
          call={""}
          fields={productFields}
          isEditableAndDeletable={false}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            padding: "50px",
          }}
        >
          <h1>{t("There aren't any products")}</h1>
        </Box>
      )}
      <div style={{ height: "20px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          component="header"
          sx={{
            fontFamily: constants.semiBold,
            fontSize: "24px",
            lineHeight: "36px",
          }}
        >
          {t("Ingredients")}
        </Typography>
        <DynamicDialog
          buttonSx={{
            margin: "20px 0 20px 0",
            display: "flex",
          }}
          title={t(
            productType === "packaged"
              ? `${
                  selectedEditIngredient === null ? "Specify" : "Edit"
                } product capacity`
              : `${
                  selectedEditIngredient === null ? "Specify" : "Edit"
                } ingredient capacity`,
          )}
          openMessage={t(
            productType === "packaged"
              ? "Add product capacity"
              : "Add ingredient capacity",
          )}
          isOpen={capacityEditDialog.show}
          hideActions
          disabled={machineProducts?.length === 0}
          onClick={() => {
            capacityEditDialog.handleShowStateChange();
            handleEditIngredientChange(null);
          }}
          onCancel={() => {
            capacityEditDialog.handleShowStateChange();
            handleEditIngredientChange(null);
          }}
          component={
            <IngredientForm
              hasCapacity
              defaultValues={
                selectedEditIngredient === null
                  ? undefined
                  : {
                      id: selectedEditIngredient.id,
                      name: selectedEditIngredient.name,
                      measurement_unit: selectedEditIngredient.unit_name,
                      quantity:
                        typeof selectedEditIngredient.quantity === "undefined"
                          ? 0
                          : selectedEditIngredient.quantity,
                      recharge_quantity:
                        selectedEditIngredient.recharge_quantity,
                      capacity: selectedEditIngredient.capacity,
                    }
              }
              defaultOptions={machineProducts
                ?.map((p) => p.ingredients)
                .flat(1)
                .filter(
                  (value, index, self) =>
                    index === self.findIndex((t) => t.id === value.id),
                )}
              submitBtnLabel="Update"
              onSubmit={(i) => {
                capacityEditDialog.handleShowStateChange();
                handleEditIngredientChange(null);

                attachIngredient(i);
              }}
            />
          }
        />
      </div>
      {machineIngredients?.length != 0 ? (
        <CustomDetailsPage
          data={machineData}
          setData={(v) => {
            setMachineData(v);
            setRefechtFlag((old) => !old);
          }}
          resource={""}
          call={""}
          fields={ingredientFields}
          isEditableAndDeletable={false}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            padding: "50px",
          }}
        >
          <h1>{t("There aren't any ingredients")}</h1>
        </Box>
      )}
    </ContentLayout>
  );
};

export default MachineDetails;

import { useTranslation } from "react-i18next";

import useAuthContext from "context/AuthContext";
import Order from "models/resources/order.model";
import Transaction from "models/resources/transaction.model";
import { dateFormatTypes } from "utils/dateFormatTypes";

const BGN2EUR: number = 1.95583;
const EUR2BGN: number = 0.51129;

interface DailyRevenue {
  BGN: number;
  EUR: number;
  date: string;
}

interface DailyTransactions {
  all: number;
  paid: number;
  failed: number;
  date: string;
}

const getDatesArray = (startDate: Date, endDate: Date): string[] => {
  if (!startDate || !endDate) return [];

  let datesArr: string[] = [];

  for (
    let currentDate: Date = new Date(startDate);
    currentDate <= new Date(endDate.getDate() + 1); // Loops to endDate + 1, because it fails to show the last day for some reason.
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    datesArr.push(new Date(currentDate).toISOString().split("T")[0]);
  }

  return datesArr;
};

// Returns chart data for transaction bar chart.
export const getTransactionBarChart = (
  transactions: Transaction[],
  dateRange: any,
) => {
  let totalPaid: number = 0;
  let totalFailed: number = 0;
  let dailyTransactions: Record<string, DailyTransactions> = {};

  const rawChartData: Transaction[] =
    typeof transactions !== "undefined" ? transactions : [];
  const startDate: string =
    typeof dateRange?.startDate !== "undefined" && dateRange?.startDate
      ? dateRange?.startDate.split("T")[0]
      : null;
  const endDate: string =
    typeof dateRange?.endDate !== "undefined" && dateRange?.endDate
      ? dateRange?.endDate.split("T")[0]
      : null;
  const filterPeriod: string[] = getDatesArray(
    new Date(startDate),
    new Date(endDate),
  );

  rawChartData.map((transaction: Transaction) => {
    const date: string = transaction.inserted_at?.split("T")[0];

    if (!filterPeriod.length || filterPeriod.includes(date)) {
      dailyTransactions[date] = dailyTransactions[date] ?? {
        all: 0,
        paid: 0,
        failed: 0,
        date: date,
      };

      dailyTransactions[date]["all"] += 1;
      dailyTransactions[date]["paid"] +=
        transaction.status.toLowerCase() === "paid_and_completed" ? 1 : 0;
      totalPaid +=
        transaction.status.toLowerCase() === "paid_and_completed" ? 1 : 0;
      dailyTransactions[date]["failed"] +=
        transaction.status.toLowerCase() !== "paid_and_completed" ? 1 : 0;
      totalFailed +=
        transaction.status.toLowerCase() !== "paid_and_completed" ? 1 : 0;
    }
  });

  const revenueDates: string[] = Object.keys(dailyTransactions);

  const emptyDays: string[] = getDatesArray(
    new Date(revenueDates[0]), // first date
    new Date(revenueDates.slice(-1)[0]), // last date
  );

  emptyDays.map((date: string) => {
    if (!revenueDates.includes(date)) {
      dailyTransactions[date] = { all: 0, paid: 0, failed: 0, date: date };
      revenueDates.push(date);
    }
  });

  let chartData: DailyTransactions[] = Object.values(dailyTransactions);

  chartData = chartData
    .sort((a, b) => {
      const x = a.date;
      const y = b.date;
      return x < y ? -1 : x > y ? 1 : 0;
    })
    .map((data) => {
      return {
        ...data,
        date: dateFormatTypes(data.date),
      };
    });

  return { chartData, totalPaid, totalFailed };
};

// Returns chart data for transaction or order line chart.
export const getChartData = (
  dataArray: Order[] | Transaction[],
  dataType: string,
  dateRange: any,
) => {
  const { t } = useTranslation();
  const { userSettings } = useAuthContext();

  let totalBGN: number = 0;
  let totalEUR: number = 0;
  let dailyRevenue: Record<string, DailyRevenue> = {};
  let totalByCurrency: Record<string, number> = {};

  const currencyName: string = userSettings.currency;
  const rawChartData: Order[] | Transaction[] =
    typeof dataArray !== "undefined" ? dataArray : [];
  const startDate =
    typeof dateRange?.startDate !== "undefined"
      ? dateRange?.startDate.split("T")[0]
      : null;
  const endDate =
    typeof dateRange?.endDate !== "undefined"
      ? dateRange?.endDate.split("T")[0]
      : null;

  const filterPeriod: string[] = getDatesArray(
    new Date(startDate),
    new Date(endDate),
  );

  rawChartData.map((data: Order | Transaction) => {
    const date: string = data.inserted_at?.split("T")[0];

    if (!filterPeriod.length || filterPeriod.includes(date)) {
      dailyRevenue[date] = dailyRevenue[date] ?? { BGN: 0, EUR: 0, date: date };

      switch (dataType.toLowerCase()) {
        case "order":
          const order: Order = data as Order;

          if (order.status.toLowerCase() === "success") {
            const orderCurrency = order.currency.toUpperCase();

            totalByCurrency[orderCurrency] = totalByCurrency[orderCurrency]
              ? totalByCurrency[orderCurrency] +
                Number((+order.quantity * +order.product_price).toFixed(2))
              : Number((+order.quantity * +order.product_price).toFixed(2));

            let paidInBGN: number =
              order.currency === "BGN"
                ? Math.round(
                    (+order.quantity * +order.product_price + Number.EPSILON) *
                      100,
                  ) / 100
                : Math.round(
                    (+order.quantity * +order.product_price * BGN2EUR +
                      Number.EPSILON) *
                      100,
                  ) / 100;
            let paidInEUR: number =
              order.currency === "EUR"
                ? Math.round(
                    (+order.quantity * +order.product_price + Number.EPSILON) *
                      100,
                  ) / 100
                : Math.round(
                    (+order.quantity * +order.product_price * EUR2BGN +
                      Number.EPSILON) *
                      100,
                  ) / 100;

            dailyRevenue[date].BGN += paidInBGN;
            dailyRevenue[date].EUR += paidInEUR;
            totalBGN += paidInBGN;
            totalEUR += paidInEUR;
          }
          break;
        case "transaction":
          const transaction: Transaction = data as Transaction;

          if (transaction.status.toLowerCase() === "paid_and_completed") {
            const transactionCurrency = transaction.currency.toUpperCase();
            const currencyAtTransaction =
              transaction.currency_at_transaction.toUpperCase();

            totalByCurrency[transactionCurrency] = totalByCurrency[
              transactionCurrency
            ]
              ? totalByCurrency[transactionCurrency] +
                Number(
                  (+transaction.amount * +transaction.rate).toFixed(
                    transactionCurrency !== "AVAX" ? 2 : 9,
                  ),
                )
              : Number(
                  (+transaction.amount * +transaction.rate).toFixed(
                    transactionCurrency !== "AVAX" ? 2 : 9,
                  ),
                );

            const paidInBGN: number =
              currencyAtTransaction === "BGN"
                ? Math.round(+transaction.amount * 100) / 100
                : currencyAtTransaction === "EUR"
                  ? Math.round(+transaction.amount * BGN2EUR * 100) / 100
                  : transactionCurrency === "BGN"
                    ? Math.round(
                        +transaction.amount * +transaction.rate * 100,
                      ) / 100
                    : Math.round(
                        +transaction.amount * +transaction.rate * BGN2EUR * 100,
                      ) / 100;
            const paidInEUR: number =
              currencyAtTransaction === "EUR"
                ? Math.round(+transaction.amount * 100) / 100
                : currencyAtTransaction === "BGN"
                  ? Math.round(+transaction.amount * EUR2BGN * 100) / 100
                  : transactionCurrency === "EUR"
                    ? Math.round(
                        +transaction.amount * +transaction.rate * 100,
                      ) / 100
                    : Math.round(
                        +transaction.amount * +transaction.rate * EUR2BGN * 100,
                      ) / 100;
            dailyRevenue[date].BGN += paidInBGN;
            dailyRevenue[date].EUR += paidInEUR;
            totalBGN += paidInBGN;
            totalEUR += paidInEUR;
          }
          break;
      }
    }
  });

  const revenueDates: string[] = Object.keys(dailyRevenue);

  const emptyDays: string[] = getDatesArray(
    new Date(revenueDates[0]), // first date
    new Date(revenueDates.slice(-1)[0]), // last date
  );

  emptyDays.map((date: string) => {
    if (!revenueDates.includes(date)) {
      dailyRevenue[date] = { BGN: 0, EUR: 0, date: date };
      revenueDates.push(date);
    }
  });

  let revenue: DailyRevenue[] = Object.values(dailyRevenue);

  revenue = revenue
    .sort((a, b) => {
      const x = a.date;
      const y = b.date;
      return x < y ? -1 : x > y ? 1 : 0;
    })
    .map((rev) => {
      return {
        BGN: Math.round((rev.BGN + Number.EPSILON) * 100) / 100,
        EUR: Math.round((rev.EUR + Number.EPSILON) * 100) / 100,
        date: dateFormatTypes(rev.date),
      };
    });

  const labels = revenue.map((r) => r.date);
  const data = revenue.map((r) =>
    userSettings.currency === "EUR" ? r.EUR : r.BGN,
  );

  totalBGN = Math.round((totalBGN + Number.EPSILON) * 100) / 100;
  totalEUR = Math.round((totalEUR + Number.EPSILON) * 100) / 100;

  const total: number = currencyName === "EUR" ? totalEUR : totalBGN;
  let revenueByCurrency: string = "";

  for (const key in totalByCurrency) {
    revenueByCurrency =
      revenueByCurrency +
      totalByCurrency[key].toFixed(key !== "AVAX" ? 2 : 9) +
      ` ` +
      t(`${key}`) +
      `, `;
  }

  return { labels, data, currencyName, total, revenueByCurrency };
};

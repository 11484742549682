import { FormEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import useAuthContext from "context/AuthContext";

export interface State {
  email: string;
  password: string;
  showPassword: boolean;
  rememberMe: boolean;
}

const LoginForm = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState<State>({
    email: "",
    password: "",
    showPassword: false,
    rememberMe: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickRememberMe = () => {
    setValues({
      ...values,
      rememberMe: !values.rememberMe,
    });
  };

  const { logIn } = useAuthContext();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    await logIn(values);
  }
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Grid container>
      <Box component="form" role="form">
        <Grid
          item
          sm={12}
          sx={{
            display: "flex",
          }}
        >
          {/* <LanguageSelect /> */}
        </Grid>
        <Grid item xs={12} textAlign="right">
          <FormControl fullWidth sx={{ margin: "0px" }} variant="filled">
            <FilledInput
              id="filled-adornment-weight-2"
              value={values.email}
              onChange={handleChange("email")}
              disableUnderline={true}
              autoFocus={false}
              endAdornment={
                <InputAdornment position="end">
                  <LockIcon />
                </InputAdornment>
              }
              placeholder={"Email"}
              aria-describedby="filled-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <Link
            style={{
              fontSize: "0.7em",
              color: "#78BCC4",
              fontFamily: "Poppins-Bold",
            }}
            to={{ pathname: "/password-forgot" }}
          >
            {t("Forgot password?")}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <FilledInput
              color="primary"
              id="filled-adornment-password"
              type={values.showPassword ? "text" : "password"}
              disableUnderline={true}
              value={values.password}
              placeholder={t("Password")}
              onChange={handleChange("password")}
              autoComplete="on"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ marginTop: "20px", marginBottom: "10px" }}
              type="submit"
            >
              {t("Login")}
            </Button>
          </FormControl>
          <Checkbox
            style={{ paddingLeft: "0" }}
            onChange={handleClickRememberMe}
          />
          <Typography style={{ display: "inline" }}>
            {t("Remember me")}
          </Typography>
          <Typography variant="body1">
            {t("Don’t have an account?")}{" "}
            <Link
              style={{
                color: "#78BCC4",
                fontFamily: "Poppins-Bold",
              }}
              to={{ pathname: "/register" }}
            >
              {t("Sign up")}
            </Link>
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};

export default LoginForm;
